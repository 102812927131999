import React from 'react'

export const Slash = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4898 3.80432L6.5795 16.844L5.51078 16.1957L13.4211 3.156L14.4898 3.80432Z"
        fill="#002D56"
      />
    </g>
  </svg>
)
