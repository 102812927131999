import React from 'react'
import PropTypes from 'prop-types'
import { Link, Tag } from './style'

const CourseCard = ({
  title,
  isDigital,
  date,
  location,
  link,
  hasWhiteBackground,
}) => {
  const formattedDate = new Date(date).toLocaleDateString('no-NB')
  return (
    <Link
      href={link.url}
      target={link.target}
      hasWhiteBackground={hasWhiteBackground}
    >
      <h3 className={'text-lg font-semibold w-full mb-[17px]'}>{title}</h3>
      <div className={'flex justify-start items-center gap-x-4'}>
        {isDigital && <Tag>Webinar</Tag>}
        <span className={'text-sm text-blue-dark'}>{formattedDate}</span>
        {location && (
          <span className={'text-sm text-blue-dark'}>{location}</span>
        )}
      </div>
    </Link>
  )
}

export default CourseCard

CourseCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isDigital: PropTypes.bool,
  date: PropTypes.string,
  location: PropTypes.string,
  backgroundColor: PropTypes.bool,
}

CourseCard.defaultProps = {
  backgroundColor: false,
}