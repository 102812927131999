import React from 'react'

export const Frown = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3327 11C10.4122 11 9.66602 11.7462 9.66602 12.6667C9.66602 13.5871 10.4122 14.3333 11.3327 14.3333C12.2532 14.3333 12.9993 13.5871 12.9993 12.6667C12.9993 11.7462 12.2532 11 11.3327 11Z"
      fill="black"
    />
    <path
      d="M20.666 11C19.7456 11 18.9993 11.7461 18.9993 12.6667C18.9993 13.5872 19.7456 14.3333 20.666 14.3333C21.5864 14.3333 22.3327 13.5872 22.3327 12.6667C22.3327 11.7461 21.5864 11 20.666 11Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9453 21.8946C19.076 21.315 17.7657 20.7292 16 20.7292C14.2344 20.7292 12.9241 21.315 12.0548 21.8946C11.6181 22.1857 11.2935 22.475 11.0822 22.6863C10.9767 22.7918 10.9003 22.877 10.8531 22.9321C10.8295 22.9596 10.8132 22.9796 10.8044 22.9906L10.7969 23L10.7981 22.9984L10.799 22.9973C10.799 22.9973 10.8001 22.9959 10.0001 22.3959C9.20005 21.7959 9.20064 21.7951 9.20064 21.7951L9.20268 21.7924L9.20608 21.7879L9.21519 21.7761C9.22228 21.7669 9.23142 21.7552 9.24262 21.7412C9.26501 21.7133 9.29565 21.6759 9.33454 21.6305C9.41229 21.5398 9.52337 21.4167 9.66794 21.2721C9.95658 20.9835 10.382 20.6061 10.9454 20.2305C12.076 19.4767 13.7657 18.7292 16 18.7292C18.2344 18.7292 19.9241 19.4768 21.0547 20.2305C21.6181 20.6061 22.0435 20.9835 22.3322 21.2721C22.4767 21.4167 22.5878 21.5398 22.6656 21.6305C22.7045 21.6759 22.7351 21.7133 22.7575 21.7412C22.7687 21.7552 22.7778 21.7669 22.7849 21.7761L22.794 21.7879L22.7974 21.7924L22.7988 21.7943C22.7988 21.7943 22.8 21.7959 22 22.3959C21.2 22.9959 21.2006 22.9966 21.2006 22.9966L21.2019 22.9984L21.2032 23L21.1957 22.9906C21.1869 22.9796 21.1706 22.9596 21.147 22.9321C21.0998 22.877 21.0234 22.7918 20.9179 22.6863C20.7066 22.475 20.382 22.1857 19.9453 21.8946Z"
      fill="black"
    />
  </svg>
)
