import tw, { styled } from 'twin.macro'

export const Link = styled.a(({ hasWhiteBackground }) => [
  tw`
  py-[18px] 
  px-6 
  w-full
  bg-blue-lighter/20
  
  md:pt-2
  md:pb-4
  md:px-4
  lg:py-[18px] 
  lg:px-6 
  
  border 
  border-2 
  border-blue-lighter 
  rounded-lg 
  flex 
  flex-col 
  
  shadow-1
`,

  hasWhiteBackground && tw`bg-white`,
])

export const Tag = styled.div`
  ${tw`
  text-sm 
  text-blue-dark 
  rounded-full 
  bg-blue-lighter 
  
  pt-2 
  px-3 
  pb-2.5
  
  lg:pt-2.5
  lg:px-4
  lg:pb-[13px]
`}
`