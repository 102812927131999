import React from 'react'
import { ContentContainer, Title } from './style'
import parse from 'html-react-parser'
import Breadcrumbs from '../breadcrumbs'
import PropTypes from 'prop-types'

const Banner = ({ image, excerpt, title, breadcrumbs }) => {
  return (
    <>
      <div
        className={
          'mx-auto flex mb-10 md:mb-16  flex-col flex-wrap  justify-between items-start  lg:xl-container md:flex-nowrap lg:flex-row '
        }
      >
        <div
          className={'xl-container lg:px-0 w-full order-last lg:order-first'}
        >
          <Breadcrumbs crumbs={breadcrumbs} />
          <ContentContainer>
            <Title>{title}</Title>
            {excerpt && (
              <div className={'text-lg md:text-xl lg:text-2xl'}>
                {parse(excerpt)}
              </div>
            )}
          </ContentContainer>
        </div>
        <div
          className={
            'rounded-b-full max-h-[240px] md:max-h-[420px] lg:max-h-[480px] w-full pr-0'
          }
        >
          {image && (
            <img
              className={
                'rounded-b-full max-h-[240px] md:max-h-[420px] lg:max-h-[480px] w-full object-cover'
              }
              src={image.publicUrl}
              alt={image.altText ? image.altText : ''}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Banner

Banner.propTypes = {
  image: PropTypes.object.isRequired,
  excerpt: PropTypes.string,
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
}
