import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button } from './style'
import { Slash } from '../../images/slash'
import { Helmet } from 'react-helmet'

const truncateText = (text) => {
  if (text.length > 15) {
    return text.substring(0, 15) + '...'
  } else return text
}

const isLastIndex = (index, list) => {
  if (index === list.length - 1) {
    return true
  }
}

const trueUrl = 'https://proteket.no'

const Breadcrumbs = ({ crumbs }) => {
  const [links, setLinks] = useState([])
  useEffect(() => {
    crumbs.map((item, index) => {
      setLinks((links) => [
        ...links,
        {
          '@type': 'ListItem',
          position: index + 1,
          name: item.text,
          item: trueUrl + item.url,
          key: index + 1,
        },
      ])
    })
  }, [crumbs])
  return (
    <>
      <Helmet>
        <script type={'application/ld+json'}>
          {JSON.stringify({
            '@context': trueUrl,
            '@type': 'BreadcrumbList',
            itemListElement: links,
          })}
        </script>
      </Helmet>
      <div className={'flex content-center items-center gap-x-2 pt-4 lg:pt-0'}>
        {crumbs
          .map((item, index) => (
            <Button
              key={index}
              path={item.url}
              isExternal={false}
              fontnormal={'true'}
              className={`${
                isLastIndex(index, crumbs) ? 'cursor-default' : ''
              }`}
            >
              <p key={item.text}>
                {index !== crumbs.length - 1 && item.text}
                {isLastIndex(index, crumbs) && truncateText(item.text)}
              </p>
            </Button>
          ))
          .reduce((prev, curr) => [prev, <Slash key={'slash'} />, curr])}
      </div>
    </>
  )
}

export default Breadcrumbs

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array,
}
