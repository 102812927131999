import tw, { styled } from 'twin.macro'

export const CategoryButton = styled.button`
  ${tw`
  py-2.5
  
  text-blue-dark
  bg-gray-100
  rounded-full
  text-base
  font-medium
  leading-4
  
  lg:py-3
  px-4
  transition 
  ease-in-out 
  duration-150
  betterhover:hover:bg-gray-200

`}
  ${({ active }) =>
    active &&
    tw`hover:bg-orange-main bg-orange-main text-white hover:text-white`}
`
export const ButtonBase = styled.button`
  ${tw`
  pt-3.5
  pb-4

  text-[17px] 
  leading-[17px]
  
  w-full
  rounded-full
  
  disabled:border-gray-400 
  disabled:text-gray-700
`}
`
export const OutlinedButton = styled(ButtonBase)`
  ${tw`
  bg-transparent 
  text-blue-dark 
  
  border-2  
  border-blue-lighter
  
  hover:text-blue-main 
  
  disabled:border-gray-400 
  disabled:text-gray-700
`}
`

export const FilledButton = styled(ButtonBase)`
  ${tw`
  bg-blue-main 
  text-blue-lightest 
  
  disabled:border-gray-400 
  disabled:text-gray-700
`}
`
