import React from 'react'
import { CategoryButton } from './style'
import PropTypes from 'prop-types'

const FilterCategories = ({
  locations,
  userGroups,
  selectedUserGroup,
  setSelectedUserGroup,
  selectedLocation,
  setSelectedLocation,
  isDigital,
  setDigital,
}) => {
  const handleLocationClick = (slug) => {
    if (selectedLocation.includes(slug)) {
      setSelectedLocation('')
    } else if (!selectedLocation.includes(slug)) {
      setSelectedLocation(slug)
      setDigital(false)
    }
  }

  const handleUserGroupClick = (slug) => {
    if (selectedUserGroup.includes(slug)) {
      setSelectedUserGroup('')
    } else if (!selectedUserGroup.includes(slug)) {
      setSelectedUserGroup(slug)
    }
  }

  const handleDigitalClick = () => {
    if (isDigital) {
      setDigital(false)
      setSelectedLocation('')
    } else if (!isDigital) {
      setDigital(true)
      setSelectedLocation('webinar')
    }
  }
  return (
    <>
      <div
        className={
          'flex flex-col lg:flex-row gap-x-12 mb-6 lg:xl-container lg:mx-auto'
        }
      >
        <div className={'flex gap-x-1 flex-wrap w-full lg:w-max '}>
          <p
            className={
              'font-semibold text-base basis-full xl-container lg:px-0'
            }
          >
            Passer for
          </p>

          {userGroups && (
            <div
              className={
                'overflow-x-auto flex gap-x-1 py-4 mb-2 px-4 sm:px-6 md:px-8 lg:px-0'
              }
            >
              <CategoryButton
                active={!selectedUserGroup}
                key={'Alle'}
                value={''}
                onClick={() => setSelectedUserGroup('')}
              >
                Alle
              </CategoryButton>
              {userGroups.map((item) => (
                <CategoryButton
                  active={selectedUserGroup.includes(item.slug)}
                  key={item.slug}
                  value={item.slug}
                  onClick={() => handleUserGroupClick(item.slug)}
                >
                  {item.name}
                </CategoryButton>
              ))}
            </div>
          )}
        </div>
        <div className={'flex gap-x-1 flex-wrap w-full lg:w-max'}>
          <p
            className={'font-semibold text-base basis-full xl-container lg:p-0'}
          >
            Hvor
          </p>

          {locations && (
            <div
              className={
                'overflow-x-auto flex gap-x-1 py-4 mb-2 px-4 sm:px-6 md:px-8 lg:px-0'
              }
            >
              <CategoryButton
                active={!selectedLocation}
                key={'Alle'}
                value={''}
                onClick={() => setSelectedLocation('') + setDigital(false)}
              >
                Alle
              </CategoryButton>
              {locations.map((item) => (
                <CategoryButton
                  active={selectedLocation.includes(item.slug)}
                  key={item.slug}
                  value={item.slug}
                  onClick={() => handleLocationClick(item.slug)}
                >
                  {item.name}
                </CategoryButton>
              ))}
              <CategoryButton
                active={isDigital}
                key={'Webinar'}
                value={''}
                onClick={() => handleDigitalClick()}
              >
                Webinar
              </CategoryButton>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default FilterCategories

FilterCategories.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.object),
  userGroups: PropTypes.arrayOf(PropTypes.object),
  selectedUserGroup: PropTypes.string,
  setSelectedUserGroup: PropTypes.func,
  selectedLocation: PropTypes.string,
  setSelectedLocation: PropTypes.func,
  isDigital: PropTypes.bool,
  setDigital: PropTypes.func,
  setOpen: PropTypes.func,
  isOpen: PropTypes.bool,
}
