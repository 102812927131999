import tw, { styled } from 'twin.macro'
import { TextButton } from '../../common/button/style'

export const Button = styled(TextButton)`
  ${tw`
  w-max
  text-base
  text-lg
  px-0
  pb-0
  border-b-2
`}
`
