import tw, { styled } from 'twin.macro'

export const ContentContainer = styled.div`
  ${tw`flex flex-col gap-y-8  lg:gap-y-32`}
`

export const Title = styled.h1`
  ${tw`
  font-medium 
  text-blue-dark
  text-4xl 
  mt-6 
  
  md:text-5xl 
  md:mt-7 
  
  lg:text-7xl 
  lg:mt-[185px] 
  `}
`
