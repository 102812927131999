import React from 'react'
import { CategoryButton } from '../../courses/filter-categories/style'
import PropTypes from 'prop-types'

const FilterCategories = ({
  categories,
  selectedCategory,
  setSelectedCategory,
}) => {
  const handleClick = (slug) => {
    if (selectedCategory.includes(slug)) {
      setSelectedCategory('')
    } else if (!selectedCategory.includes(slug)) {
      setSelectedCategory(slug)
    }
  }

  return (
    <>
      <div
        className={
          'flex flex-col lg:flex-row gap-x-12 mb-6 lg:xl-container lg:mx-auto'
        }
      >
        <div className={'flex gap-x-1 flex-wrap w-full lg:w-max '}>
          <p
            className={
              'font-semibold text-base basis-full xl-container lg:px-0'
            }
          >
            Kategori
          </p>

          {categories && (
            <div
              className={
                'overflow-x-auto flex gap-x-1 py-4 mb-2 px-4 sm:px-6 md:px-8 lg:px-0'
              }
            >
              <CategoryButton
                active={!selectedCategory}
                key={'Alle'}
                value={''}
                onClick={() => setSelectedCategory('')}
              >
                Alle
              </CategoryButton>
              {categories.map((item) => (
                <CategoryButton
                  active={selectedCategory.includes(item.slug)}
                  key={item.slug}
                  value={item.slug}
                  onClick={() => handleClick(item.slug)}
                >
                  {item.name}
                </CategoryButton>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default FilterCategories

FilterCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func,
}