import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import FilterCategories from './filter-categories'
import CourseCard from './course/card'
import { Frown } from '../../images/frown'
import {
  filterDoesNotHavesMatchesEvent,
  filterHasMatchesEvent,
} from '../google-tag-manager/events'

const Courses = ({
  courses,
  locations,
  userGroups,
  selectedUserGroup,
  setSelectedUserGroup,
  selectedLocation,
  setSelectedLocation,
  isDigital,
  setDigital,
  courseNotFoundText,
}) => {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    courses.length > 0
      ? filterHasMatchesEvent('kurs')
      : filterDoesNotHavesMatchesEvent('kurs')
  }, [courses])

  return (
    <>
      <FilterCategories
        locations={locations}
        userGroups={userGroups}
        selectedUserGroup={selectedUserGroup}
        setSelectedUserGroup={setSelectedUserGroup}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        isDigital={isDigital}
        setDigital={setDigital}
        setOpen={setOpen}
        isOpen={isOpen}
      />
      <div className={'xl-container mx-auto mt-8 mb-20 lg:mb-16'}>
        <div className={'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6'}>
          {courses.map((item, index) => (
            <CourseCard
              key={index}
              isDigital={item.isDigital}
              title={item.title}
              date={item.date}
              location={item.location}
              subtitle={item.subtitle}
              link={item.link}
            />
          ))}
        </div>
        {courses.length === 0 && (
          <div
            className={
              'flex flex-col items-center justify-center w-full gap-y-2 pt-8'
            }
          >
            <span
              className={
                'rounded-full bg-blue-lighter h-10 w-10 flex items-center justify-center'
              }
            >
              <Frown />
            </span>
            <p className={'text-lg text-blue-dark'}>{courseNotFoundText}</p>
          </div>
        )}
      </div>
    </>
  )
}

export default Courses

Courses.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object),
  locations: PropTypes.arrayOf(PropTypes.object),
  userGroups: PropTypes.arrayOf(PropTypes.object),
  selectedUserGroup: PropTypes.string,
  setSelectedUserGroup: PropTypes.func,
  selectedLocation: PropTypes.string,
  setSelectedLocation: PropTypes.func,
  isDigital: PropTypes.bool,
  setDigital: PropTypes.func,
  link: PropTypes.arrayOf(PropTypes.object),
}
