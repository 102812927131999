import tw, { styled } from 'twin.macro'
import { TextButton } from '../common/button/style'

export const Button = styled(TextButton)`
  ${tw`
  text-sm
  text-blue-dark 
  border-orange-main
  border-b-2
  w-max
  p-0
  m-0
  last:border-none
  `}
`

