import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import FilterCategories from './filter/index'
import { Frown } from '../../images/frown'
import {
  filterDoesNotHavesMatchesEvent,
  filterHasMatchesEvent,
} from '../google-tag-manager/events'
import WebinarCard from './card'

const Webinars = ({
  webinars,
  categories,
  selectedCategory,
  setSelectedCategory,
  webinarNotFoundText,
}) => {
  useEffect(() => {
    webinars.length > 0
      ? filterHasMatchesEvent('webinar')
      : filterDoesNotHavesMatchesEvent('webinar')
  }, [categories])

  return (
    <>
      <h3
        className={
          'xl-container mx-auto font-semibold text-blue-dark text-4xl lg:text-[2.5rem] lg:leading-[3rem] mb-4 lg:mb-6'
        }
      >
        Opptak fra tidligere webinarer
      </h3>
      <FilterCategories
        categories={categories}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <div className={'xl-container mx-auto mt-8 mb-20 lg:mb-16'}>
        <div className={'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6'}>
          {webinars.map((item, index) => (
            <WebinarCard key={index} {...item} />
          ))}
        </div>
        {webinars.length === 0 && (
          <div
            className={
              'flex flex-col items-center justify-center w-full gap-y-2 pt-8'
            }
          >
            <span
              className={
                'rounded-full bg-blue-lighter h-10 w-10 flex items-center justify-center'
              }
            >
              <Frown />
            </span>
            <p className={'text-lg text-blue-dark'}>{webinarNotFoundText}</p>
          </div>
        )}
      </div>
    </>
  )
}

export default Webinars

Webinars.propTypes = {
  webinars: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.object),
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func,
}