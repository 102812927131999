import React from 'react'
import PropTypes from 'prop-types'
import { Button } from './style'

const ContactSection = ({ image, title, name, jobTitle, phone, email }) => {
  return (
    <div
      className={
        'w-screen bg-blue-lighter py-6 px-4 md:py-6 md:px-14 lg:px-44 lg:py-20'
      }
    >
      <div className={'max-w-screen-2xl mx-auto'}>
        <h3
          className={
            'text-3xl md:text-4xl lg:text-5xl font-medium text-blue-main mb-8'
          }
        >
          {title}
        </h3>
        <div className={'flex gap-x-6 gap-y-4 items-center flex-wrap'}>
          {image && (
            <div
              className={
                'rounded-full overflow-hidden h-24 w-24 lg:h-32 lg:w-32'
              }
            >
              <img
                className={'w-full h-full rounded-full'}
                src={image.publicUrl}
                alt={image.altText ? image.altText : ''}
              />
            </div>
          )}
          <div className={'flex flex-col gap-y-1'}>
            <p className={'text-xl lg:text-2xl font-semibold text-blue-main'}>
              {name}
            </p>
            {jobTitle && (
              <p className={'text-base lg:text-lg text-blue-main'}>
                {jobTitle}
              </p>
            )}
            <div className={'flex gap-x-6'}>
              {phone && (
                <Button
                  path={phone.url}
                  target={phone.target}
                  isExternal={true}
                >
                  {phone.title}
                </Button>
              )}
              {email && (
                <Button
                  path={email.url}
                  target={email.target}
                  isExternal={true}
                >
                  {email.title}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactSection

ContactSection.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  phone: PropTypes.object,
  email: PropTypes.object,
}
