import React from 'react'
import PropTypes from 'prop-types'
import { Link, Tag } from '../../courses/course/card/style'

const WebinarCard = ({
  title,
  date,
  link,
  categories,
  hasWhiteBackground,
  paid,
  price,
}) => {
  const formattedDate = new Date(date).toLocaleDateString('no-NB')
  return (
    <Link href={link} hasWhiteBackground={hasWhiteBackground}>
      <h3 className={'text-lg font-semibold w-full mb-[17px]'}>{title}</h3>
      <div className={'flex justify-start flex-wrap items-center gap-x-2 gap-y-1'}>
        {categories &&
          categories.map((item) => <Tag key={item.name}>{item.name}</Tag>)}
      </div>
      <div className={'pt-2.5 flex gap-1 items-center'}>
        <span className={'text-sm text-blue-dark'}>{formattedDate}</span>
        {paid ? (
          <span className={'text-sm text-blue-dark'}>• {price}kr</span>
        ) : (
          <span className={'text-sm text-blue-dark'}>• Gratis</span>
        )}
      </div>
    </Link>
  )
}

export default WebinarCard

WebinarCard.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  categories: PropTypes.array,
  date: PropTypes.string,
  backgroundColor: PropTypes.bool,
  hasWhiteBackground: PropTypes.bool,
  paid: PropTypes.bool,
  price: PropTypes.number,
}

WebinarCard.defaultProps = {
  backgroundColor: false,
}